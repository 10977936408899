<!--
 * @Author: lyt
 * @Date: 2023-09-09 10:00:52
 * @LastEditTime: 2024-08-19 17:02:58
 * @LastEditors: lyt
 * @Description: 招聘考试页面
 * @FilePath: /official_web_portal/official/src/views/joinUs/examList.vue
 *  
-->
<template>
    <div class="examPage">
        <div v-if="!isMobile">
            <div class="firstWrapper">
                <img src="../../assets/images/joinus/mobile//exam/banner_onlineExam.svg"
                    style="width: 100%" />
            </div>
            <div class="tap">
                <div class="pc_change">
                    <div>
                        <a @click.prevent="recruitBtnDidiClick(1)">社会招聘</a>
                        <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                        <a @click.prevent="recruitBtnDidiClick('second')">校园招聘</a>
                        <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                        <a class="tab-active">在线考试</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="list_card">
                    <div class="list_item" v-for="listItem in examList" :key="listItem.typeNum">
                        <div class="item_left" :style="{ background: `${listItem.background}` }">
                            <span>{{ listItem.typeNum }}</span>
                            <span>{{ listItem.typeName }}</span>
                            <span v-if="listItem.remark && listItem.remark !== ''" style="  margin-top: 10px;">{{
                                listItem.remark
                                }}</span>
                        </div>
                        <div class="item" v-for="item in listItem.itemList" :key="item.item_num">
                            <span class="item_num">{{ item.num }}</span>
                            <span>{{ item.name }}</span>
                            <span>{{ item.leval }}</span>
                            <div class="btn" @click="goToExamUrl(item.url)">
                                <span class="btn_text">开始考试</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="m_wrapper mobile_exam" v-else>
            <div id="mobile_img">
                <div class="tip">
                    <span>艾融软件笔试考试</span>
                    <span>请在电脑端打开链接</span>
                    <span>进行考试</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { isMobile } from "../../utils/index";
export default {
    name: "ExamList",
    data() {
        return {
            isMobile: false,
            examList: [
                {
                    typeNum: 'A',
                    typeName: 'Java工程师',
                    background: 'linear-gradient(136deg, #E233FF 0%, #FF6B00 100%)',
                    itemList: [{
                        num: 'A01',
                        name: 'Java工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/c8b81e',
                    },
                    {
                        num: 'A02',
                        name: 'Java工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/50cdbc',
                    },
                    {
                        num: 'A03',
                        name: 'Java工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/27b542',
                    },
                    ],
                },
                {
                    typeNum: 'B',
                    typeName: '前端工程师',
                    remark: '(vue等)',
                    background: 'linear-gradient(136deg, #FFC200 0%, #FF785E 100%)',
                    itemList: [{
                        num: 'B01',
                        name: '前端工程师(vue等)',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/5ead4a',
                    },
                    {
                        num: 'B02',
                        name: '前端工程师(vue等)',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/1d8ce4',
                    },
                    {
                        num: 'B03',
                        name: '前端工程师(vue等)',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/fe7e47',
                    },
                    ],
                },
                {
                    typeNum: 'C',
                    typeName: '前端工程师',
                    remark: '(react等)',
                    background: 'linear-gradient(136deg, #33CC99 0%, #FFCC33 100%)',
                    itemList: [{
                        num: 'C01',
                        name: '前端工程师(react等)',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/bf08f4',
                    },
                    {
                        num: 'C02',
                        name: '前端工程师(react等)',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/b23652',
                    },
                    {
                        num: 'C03',
                        name: '前端工程师(react等)',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/d0e3b7',
                    },
                    ],
                },
                {
                    typeNum: 'D',
                    typeName: 'iOS工程师',
                    background: 'linear-gradient(136deg, #2AA6DA 0%, #1B7B77 100%)',
                    itemList: [{
                        num: 'D01',
                        name: 'iOS工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/702fd0',
                    },
                    {
                        num: 'D02',
                        name: 'iOS工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/974689',
                    },
                    {
                        num: 'D03',
                        name: 'iOS工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/ca9b5b',
                    },
                    ],
                },
                {
                    typeNum: 'E',
                    typeName: '安卓工程师',
                    background: 'linear-gradient(136deg, #000066 0%, #6699FF 100%)',
                    itemList: [{
                        num: 'E01',
                        name: '安卓工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/d1d691',
                    },
                    {
                        num: 'E02',
                        name: '安卓工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/c1bec8',
                    },
                    {
                        num: 'E03',
                        name: '安卓工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/17f3ac',
                    },
                    ],
                },
                {
                    typeNum: 'F',
                    typeName: '大数据工程师',
                    background: 'linear-gradient(136deg, #F40076 0%, #342771 100%)',
                    itemList: [{
                        num: 'F01',
                        name: '大数据工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/ddfc60',
                    },
                    {
                        num: 'F02',
                        name: '大数据工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/2f73a4',
                    },
                    {
                        num: 'F03',
                        name: '大数据工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/ba449a',
                    },
                    ],
                },
                {
                    typeNum: 'G',
                    typeName: '数据分析工程师',
                    background: 'linear-gradient(136deg, #EA4C89 0%, #B2FFDA 100%)',
                    itemList: [{
                        num: 'G01',
                        name: '数据分析工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/b74135',
                    },
                    {
                        num: 'G02',
                        name: '数据分析工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/d36673',
                    },
                    {
                        num: 'G03',
                        name: '数据分析工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/7b73b9',
                    },
                    ],
                },
                {
                    typeNum: 'H',
                    typeName: 'C++工程师',
                    background: 'linear-gradient(136deg, #2F80ED 0%, #B2FFDA 100%)',
                    itemList: [{
                        num: 'H01',
                        name: 'C++工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/85738f',
                    },
                    {
                        num: 'H02',
                        name: 'C++工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/2c38f2',
                    },
                    {
                        num: 'H03',
                        name: 'C++工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/00c827',
                    },
                    ],
                },
                {
                    typeNum: 'I',
                    typeName: '测试工程师',
                    remark: '（非功能性）',
                    background: 'linear-gradient(136deg, #462066 0%, #A3E7FC 100%)',
                    itemList: [{
                        num: 'I01',
                        name: '测试工程师（非功能性）',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/c5c98f',
                    },
                    {
                        num: 'I02',
                        name: '测试工程师（非功能性）',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/b68b707',
                    },
                    {
                        num: 'I03',
                        name: '测试工程师（非功能性）',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/ade757',
                    },
                    ],
                },
                {
                    typeNum: 'J',
                    typeName: '测试工程师',
                    remark: '（功能性）',
                    background: 'linear-gradient(135deg, #462066 0%, #FCF4D9 100%, #FCF4D9 100%)',
                    itemList: [{
                        num: 'J01',
                        name: '测试工程师（功能性）',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/e36509',
                    },
                    {
                        num: 'J02',
                        name: '测试工程师（功能性）',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/03fb3e5',
                    },
                    {
                        num: 'J03',
                        name: '测试工程师（功能性）',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/6dabe2',
                    },
                    ],
                },
                {
                    typeNum: 'K',
                    typeName: '运维工程师',
                    background: 'linear-gradient(136deg, #D5E1DD 0%, #747E80 100%)',
                    itemList: [{
                        num: 'K01',
                        name: '运维工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/f7c035',
                    },
                    {
                        num: 'K02',
                        name: '运维工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/9b46f6',
                    },
                    {
                        num: 'K03',
                        name: '运维工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/d5c923',
                    },
                    ],
                },
                {
                    typeNum: 'L',
                    typeName: '.net工程师',
                    background: 'linear-gradient(136deg, #FFBF00 0%, #4A33E8 100%)',
                    itemList: [{
                        num: 'L01',
                        name: '.net工程师',
                        leval: '初级',
                        url: '',
                    },
                    {
                        num: 'L02',
                        name: '.net工程师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/4df12a',
                    },
                    {
                        num: 'L03',
                        name: '.net工程师',
                        leval: '高级',
                        url: '',
                    },
                    ],
                },
                {
                    typeNum: 'M',
                    typeName: 'UI设计师',
                    background: 'linear-gradient(135deg, #F06966 0%, #FAD6A6 100%)',
                    itemList: [{
                        num: 'M01',
                        name: 'UI设计师',
                        leval: '初级',
                        url: '',
                    },
                    {
                        num: 'M02',
                        name: 'UI设计师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/17a5fb',
                    },
                    {
                        num: 'M03',
                        name: 'UI设计师',
                        leval: '高级',
                        url: '',
                    },
                    ],
                },
                {
                    typeNum: 'N',
                    typeName: '高级人才顾问',
                    background: 'linear-gradient(135deg, #54D2EF 0%, #2AA6DA 100%)',
                    itemList: [{
                        num: 'N01',
                        name: '高级人才顾问',
                        leval: 'MBTI职业性格测试',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/2e0679',
                    },
                    {
                        num: 'N02',
                        name: '高级人才顾问',
                        leval: '招聘专业笔试',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/178f4d',
                    },
                    ],
                },
                {
                    typeNum: 'O',
                    typeName: 'ETL数据开发工程师',
                    background: 'linear-gradient(135deg, #00F0FF 0%, #8000FF 100%)',
                    itemList: [{
                        num: 'O01',
                        name: 'ETL数据开发工程师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/0fb012',
                    },
                    {
                        num: 'O02',
                        name: 'ETL数据开发工程师',
                        leval: '中级',
                        url: ' https://i.kaoshiyun.com.cn/v5/exam/86b61e',
                    },
                    {
                        num: 'O03',
                        name: 'ETL数据开发工程师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/66c797',
                    },
                    ],
                },
                {
                    typeNum: 'P',
                    typeName: '需求分析师',
                    background: 'linear-gradient(135deg, #6699FF 0%, #FF3366 100%)',
                    itemList: [{
                        num: 'P01',
                        name: '需求分析师',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/0e79df',
                    },
                    {
                        num: 'P02',
                        name: '需求分析师',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/b049e4',
                    },
                    {
                        num: 'P03',
                        name: '需求分析师',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/2cf73d',
                    },
                    ],
                },
                {
                    typeNum: 'Q',
                    typeName: '前端工程师鸿蒙系统',
                    background: 'linear-gradient(135deg, #6699FF 0%, #31408F 100%)',
                    itemList: [{
                        num: 'Q01',
                        name: '前端工程师鸿蒙系统',
                        leval: '初级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/89b373',
                    },
                    {
                        num: 'Q02',
                        name: '前端工程师鸿蒙系统',
                        leval: '中级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/3b7974',
                    },
                    {
                        num: 'Q03',
                        name: '前端工程师鸿蒙系统',
                        leval: '高级',
                        url: 'https://i.kaoshiyun.com.cn/v5/exam/fd2a66',
                    },
                    ],
                },
                {
                    typeNum: 'R',
                    typeName: '艾融软件项目经理',
                    background: 'linear-gradient(135deg, #0002D2 0%, #FF785E 100%)',
                    itemList: [
                        {
                            num: 'R01',
                            name: '艾融软件项目经理',
                            leval: '中级',
                            url: 'https://i.kaoshiyun.com.cn/v5/exam/1bebd5',
                        },
                        {
                            num: 'R02',
                            name: '艾融软件项目经理',
                            leval: '高级',
                            url: 'https://i.kaoshiyun.com.cn/v5/exam/111542',
                        },
                    ],
                }
            ],

        }
    },
    mounted() {
        this.isMobile = isMobile();
        if (this.isMobile) {
            setTimeout(function () {
                const mobileImg = document.getElementById('mobile_img')
                const bodyHeight = document.body.clientHeight;
                mobileImg.style.height = (bodyHeight - 80) + 'px';
            }, 100);
        }

    },
    methods: {
        // 点击链接前往考试页面
        goToExamUrl(url) {
            if (url && url !== '') {
                window.open(url)

            }
        },

        // 跳转招聘页面
        recruitBtnDidiClick(type) {
            if (typeof type === "string" && type !== "second") {
                return;
            }
            if (typeof type === "string" && type === "second") {
                type = 99;
            }
            this.$router.push({ path: "/recruit", query: { category: type } });
            this.$router.afterEach(() => {
                window.scrollTo(0, 0);
            });
        },
    }
}
</script>
<style lang="less" scoped>
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.examPage {
    width: 100%;
    height: 100%;
    cursor: default;
    background: #fff;

    .tap {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: flex-end;
        // background: #f6f6f6 !important;
        background: #f6f6f6 !important;
        cursor: pointer;
        font-size: 15px;

        .pc_change {
            position: absolute;
            left: 60%;
            z-index: 1;

            div {
                margin-right: 10px;
                position: relative;
                margin-top: 6%;
                cursor: default;

                .active {
                    padding-bottom: 5px;
                    border-bottom: 2px solid #265ea2;
                    color: #265ea2;
                }
            }

            a:nth-child(2n-1) {
                text-decoration: none;
                color: #000000;
                font-size: 15px;
                cursor: pointer;
            }

            a:nth-child(2) {
                font-size: 15px;
            }

            a:nth-child(2n-1):hover {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }

            a:nth-child(2n-1):visited {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }

            .tab-active {
                color: #245DA1 !important;
                font-weight: bolder;
                border-bottom: 2px solid rgba(36, 93, 161, 1);
                line-height: 33px;
                height: 33px;
                padding: 4px 0;
            }
        }
    }

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .list_item {
        width: 100%;
        margin: 2.083vw 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;

    }

    .item_left {
        width: 14.427vw;
        border-radius: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 1.25vw 0;

        span {
            font-size: 1.666vw;
            font-weight: 900;
            line-height: 1;
            letter-spacing: 0px;
            color: #fff;
        }

        span:first-child {
            font-size: 7.5vw;
            font-weight: 900;
            line-height: 1;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 0.2;
            margin-bottom: 1.25vw;

        }
    }

    .item {
        width: 14.427vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 1.875vw;
        border: 1px solid #bdbcbc5a;
        padding-top: 2vw;
        padding-bottom: 1.5vw;

        span {
            font-size: 1.04vw;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            letter-spacing: 0px;

        }


        .item_num {
            font-size: 3.333vw;
            font-weight: bold;
            line-height: 3.333vw;
            text-align: center;
            letter-spacing: 1.5px;
            margin-bottom: 0.414vw;
        }

        .btn {
            height: 2.7vw;
            margin-top: 2.5vw;
            border-radius: 8px;
            background: #245DA1;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn_text {
                padding: 0.9375vw 1.897vw;
                min-width: 80px;
                font-size: 1.041vw;
                font-weight: normal;
                letter-spacing: 0px;
                color: #fff;
                line-height: 1;
            }
        }
    }

    .mobile_exam {
        width: 100%;
        height: 100%;

        .after-scrollMenu {
            width: 100vw;
            height: 60px;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 5;
            display: flex;
            background: #FFF;
            box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
            justify-content: space-between;
            align-items: center;

            .left_logo {
                width: 114px;
                height: 30px;
                margin-left: 20px;

                img {
                    width: 115px;
                    height: 30px;
                }
            }

            .right_menu {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                margin-right: 20px;

                .right_menuImg {
                    width: 20px;
                    height: 16px;
                }
            }
        }

        #mobile_img {
            width: 100%;
            background: url('../../assets/images/joinus/mobile_exam.png');
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;

            .tip {
                width: 100%;
                position: fixed;
                top: 196px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 28px;
                    text-align: center;
                    letter-spacing: 0px;
                    margin-bottom: 4px;
                    text-shadow: 0px 8px 40px 2px rgba(0, 0, 0, 0.9);
                }

                span:first-child {
                    font-size: 24px;
                    font-weight: normal;
                    line-height: 28px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }



    }
}


@media screen and (min-width: 576px) and (max-width: 768px) {
    .examPage {
        .item .btn {
            height: 3.5vw;
        }

        .item .btn .btn_text {
            font-size: 12px;
        }
    }
}

</style>
